import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueRx from "@nopr3d/vue-next-rx";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import "bootstrap";

import Toast from "@/shared/support/Toast";
import Debug from "@/shared/support/Debug";

import AButton from "@/shared/components/common/AButton.vue";
import AuggieIcon from "@/shared/components/common/AuggieIcon.vue";
import ButtonButton from "@/shared/components/common/ButtonButton.vue";
import Help from "@/components/Help.vue";
import FormLabel from "@/shared/components/common/FormLabel.vue";
import FormRow from "@/shared/components/common/FormRow.vue";
import Loading from "@/shared/components/common/Loading.vue";
import NumericKeyboard from "@/shared/components/common/NumericKeyboard.vue";
import DecimalKeyboard from "@/shared/components/common/DecimalKeyboard.vue";
import Selection from "@/shared/components/common/Selection.vue";
import SpinButton from "@/shared/components/common/SpinButton.vue";
import SpinX from "@/shared/components/common/SpinX.vue";
import ValidationMessage from "@/shared/components/common/ValidationMessage.vue";

import "@/styles/app.scss";

// Register all fontawesome icons
// https://github.com/FortAwesome/vue-fontawesome
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
    faAsterisk, faBan, faCheckCircle, faCog, faHome, faWifi, faSpinner, faNetworkWired,
    faBatteryEmpty, faBatteryQuarter, faBatteryHalf, faBatteryThreeQuarters, faBatteryFull, faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";

import "@/services/Auggie";
import { BehaviorSubject } from "rxjs";

export let AUGGIEAVAILABLE = true;

if (process.env.VUE_APP_DEBUG === "1") {
    // allow conditional initialization
    try {
        Auggie.System.Initialize({
            SubjectFactory: <T>(x: T) => new BehaviorSubject<T>(x)
        });
    } catch(e) {
        AUGGIEAVAILABLE = false;// can only happen in DEBUG mode, stand-alone
    }
} else {
    Auggie.System.Initialize({
        SubjectFactory: <T>(x: T) => new BehaviorSubject<T>(x)
    });
}

library.add(
    faAsterisk, faBan, faCheckCircle, faCog, faHome, faWifi, faExclamationTriangle, faSpinner, faNetworkWired,
    faBatteryEmpty, faBatteryQuarter, faBatteryHalf, faBatteryThreeQuarters, faBatteryFull
);

const appObject = createApp(App);

appObject.use(VueRx);

Toast.initializeToaster(appObject);
Debug.initialize();

// register commonly used components
appObject.component("AuggieIcon", AuggieIcon);
appObject.component("AButton", AButton);
appObject.component("ButtonButton", ButtonButton);
appObject.component("FaIcon", FontAwesomeIcon);
appObject.component("FormLabel", FormLabel);
appObject.component("FormRow", FormRow);
appObject.component("Help", Help);
appObject.component("Loading", Loading);
appObject.component("NumericKeyboard", NumericKeyboard);
appObject.component("DecimalKeyboard", DecimalKeyboard);
appObject.component("Selection", Selection);
appObject.component("SpinButton", SpinButton);
appObject.component("SpinX", SpinX);
appObject.component("ValidationMessage", ValidationMessage);

appObject.use(router);

appObject.mount("#app");

dom.watch();
// This will kick of the initial replacement of i to svg tags and configure a MutationObserver